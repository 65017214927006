@import "./styles/colors.css";
@import "./styles/fonts.css";
@import "./styles/scroll-bar.css";
/* styles dinamic theme */

:root {
  --bg-white: #fff;
  --color-white: #fff;
  --bg-global-modal-dark: #fff;
  --text-color-modal: rgba(0, 0, 0, 0.88);
  --ant-btn-modal: #fff;
  --body-hover-table: #cccccc2c;
  --oldTable: #f4f4f4fa;
  --evenTable: #fff;
  --scrollbar-color: #ccccccc0 #f5f5f5;
  --box-shadow-dark: rgb(18 38 63 / 41%);
  --bg-global-wrapper: #fff;
  --bg-global-avatar-dark: #efefef;
  --bg-scrollbar-thumb: #c7c5c5;
  --color-input: #43ec15;
  --border-dark-mode: #d9d9d9;
  --place-holder: #ccc;
  --bg-hover: #cccccc5c;
  --clear: #3f37375c;
  --primary-button: #1d37a2;
}
[data-theme="dark"] {
  --bg-dark-mode: #212121;
  --border-dark-mode: #4d4c4c;
  --bg-dark-mode-content: #0f0f0f;
  --bg-global-button: #767676;
  --bg-global-table: #141414;
  --color-dark: rgba(0, 0, 0, 0.88);
  --bg-global-wrapper: #141414;
  --bg-global-modal-dark: #2c2c2c;
  --bg-global-avatar-dark: #383636;
  --text-color-modal: #fff;
  --ant-btn-modal: #141414;
  --body-hover-table: #1e1c1c;
  --oldTable: #1e1c1c;
  --evenTable: rgb(61, 60, 60);
  --scrollbar-color: #00000070 #272222e6;
  --box-shadow-dark: #112f03;
  --bg-scrollbar-thumb: #202020;
  --color-input: #43ec15;
  --place-holder: #5c5c5ced;
  --bg-hover: #cccccc0a;
  --clear: #43ec15;
  --primary-button: #141414;
}
/* fin */
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #52c419 !important;
  border-color: #52c419 !important;
}
.ant-checkbox:hover {
  border-color: #52c419 !important;
}
.theme-wrapper {
  border-radius: 8px;
  padding: 8px 26px;
  background-color: var(--bg-global-wrapper) !important;
  box-shadow: 0px 0px 18px var(--box-shadow-dark) !important;
}
.ant-popover .ant-popover-inner {
  background-color: var(--bg-global-wrapper) !important;
}
.App {
  text-align: center;
}

.ant-select-selector {
  border: 1px solid var(--border-dark-mode) !important;
  background-color: var(--bg-global-wrapper) !important;
}
.ant-select-selector:hover {
  border: 1px solid var(--color-input) !important;
}
.ant-select-dropdown {
  background-color: var(--bg-global-wrapper) !important;
}
.ant-select-dropdown .ant-select-item {
  color: var(--text-color-modal) !important;
}
.ant-select-dropdown
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: var(--bg-global-wrapper) !important;
}
.ant-select-selector .ant-select-selection-placeholder,
.ant-select-arrow {
  color: var(--place-holder) !important;
}
.ant-btn-primary {
  background: var(--ant-btn-modal);
  border-color: #ccc;
  color: var(--text-color-modal) !important;
}
.ant-btn-primary:hover {
  background: var(--primary-button) !important;
  border-color: var(--primary-button) !important;
  color: #fff !important;
}
.ant-btn {
  border-radius: 18px;
}
.ant-modal .ant-modal-header .title-modal-global {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
}
.ant-popover .ant-popover-title {
  color: var(--text-color-modal) !important;
}
.ant-modal .ant-modal-close {
  color: var(--text-color-modal) !important;
}
.deleted {
  color: var(--text-color-modal) !important;
}
.ant-select-selector .ant-select-selection-search-input {
  color: var(--text-color-modal) !important;
}
.text-emtpty-custom {
  color: var(--text-color-modal) !important;
}
.upload-text {
  color: var(--text-color-modal) !important;
}
.ant-select .ant-select-clear {
  color: var(--clear) !important;
}
.ant-input-outlined:hover {
  border: 1px solid var(--color-input) !important;
}
.ant-list-item:hover {
  border-radius: 6px;
  /* background: var(--bg-hover); */
}
.ant-modal-title .modal-custon {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
}
.ant-modal .ant-modal-header {
  background: transparent !important;
  color: var(--text-color-modal) !important;
}
.ant-modal .ant-modal-header .ant-modal-title {
  color: var(--text-color-modal) !important;
}
.ant-modal .ant-modal-content {
  background-color: var(--bg-global-modal-dark) !important;
  padding: 18px 24px !important;
}
.ant-modal-confirm .ant-modal-confirm-title,
.ant-modal-confirm-content {
  color: var(--text-color-modal) !important;
}
.ant-modal-confirm-btns .ant-btn-primary {
  background-color: var(--primary-button) !important;
  color: var(--color-white) !important;
}
.ant-drawer .ant-drawer-content {
  background-color: var(--bg-global-modal-dark) !important;
}
.ant-drawer-title {
  color: var(--text-color-modal) !important;
}
.ant-drawer-title .title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 3px;
}
/* clases de los tabs title */
.title-tab {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  color: var(--text-color-modal) !important;
}
.ant-tabs .ant-tabs-nav-operations .ant-tabs-nav-more .anticon-ellipsis {
  color: var(--text-color-modal) !important;
}
.ant-tabs-dropdown .ant-tabs-dropdown-menu {
  background-color: var(--bg-global-modal-dark) !important;
}
.avatar-workflow {
  background-color: var(--bg-global-avatar-dark) !important;
}
/* fin */
.ant-table-cell-row-hover {
  background: var(--body-hover-table) !important;
}
/* Color de fondo para las filas pares */
/* Estilos para filas alternas en toda la tabla */
.table-container .table-theme .ant-table-tbody tr:nth-child(even) {
  background-color: var(--evenTable) !important;
}

.table-container .table-theme .ant-table-tbody tr:nth-child(odd) {
  background-color: var(--oldTable) !important;
}

/* Alternar colores en celdas fijas según la fila */
.table-container
  .table-theme
  .ant-table-tbody
  > tr:nth-child(even)
  .ant-table-cell-fix-left,
.ant-table-tbody > tr:nth-child(even) .ant-table-cell-fix-right {
  background-color: var(--evenTable) !important;
}

.table-container
  .table-theme
  .ant-table-tbody
  tr:nth-child(odd)
  .ant-table-cell-fix-left,
.ant-table-tbody tr:nth-child(odd) .ant-table-cell-fix-right {
  background-color: var(--oldTable) !important;
}

.ant-table-cell-fix-left,
.ant-table-cell-fix-right {
  position: relative;
  z-index: 10; /* Ajusta según sea necesario */
}
.ant-table-body {
  scrollbar-color: var(--scrollbar-color);
  scrollbar-width: thin;
}
.ant-space-align-center {
  display: flex;
  justify-content: center;
}

.ant-table-wrapper .ant-table-cell {
  padding: 10px 16px !important;
}
.ant-table-body::-webkit-scrollbar {
  width: 10px; /* Ancho del scrollbar */
}

.ant-table-body::-webkit-scrollbar-track {
  background: #f5f5f5; /* Color de fondo del track (el fondo del scrollbar) */
}

.ant-table-body::-webkit-scrollbar-thumb {
  background-color: #c1c1c1; /* Color de la "thumb" (la parte que se desplaza) */
  border-radius: 10px; /* Borde redondeado */
  border: 2px solid #f5f5f5; /* Borde alrededor de la thumb */
}
.ant-table-body::-webkit-scrollbar-thumb:hover {
  background-color: #a0a0a0; /* Color de la thumb al pasar el cursor por encima */
}

::-webkit-scrollbar {
  width: 10px; /* Ancho del scrollbar */
  height: 12px;
}
::-webkit-scrollbar-track {
  background: var(
    --scrollbar-color
  ); /* Color de fondo del track (el fondo del scrollbar) */
}
::-webkit-scrollbar-thumb {
  background-color: var(
    --bg-scrollbar-thumb
  ); /* Color de la "thumb" (la parte que se desplaza) */
  border-radius: 18px !important; /* Borde redondeado */
  border: 1px solid var(--bg-scrollbar-thumb); /* Borde alrededor de la thumb */
}
::-webkit-scrollbar-thumb:hover {
  background-color: #a0a0a08f; /* Color de la thumb al pasar el cursor por encima */
}
.dropmenu-top {
  width: 100% !important;
  display: flex;
  justify-content: flex-start;
  gap: 4px;
}
.ant-picker-calendar,
.ant-picker-panel {
  background-color: var(--bg-global-modal-dark) !important;
}
.ant-picker-calendar .ant-picker-content th {
  color: var(--text-color-modal) !important;
}
.ant-picker-calendar .ant-picker-cell-in-view {
  color: var(--text-color-modal) !important;
}
.ant-picker-calendar-header .ant-select-selection-item {
  color: var(--text-color-modal) !important;
}
.ant-radio-button-wrapper-checked {
  background-color: var(--bg-global-modal-dark) !important;
  color: var(--text-color-modal) !important;
}
.ant-radio-button-wrapper {
  background-color: var(--bg-global-modal-dark) !important;
  color: var(--text-color-modal) !important;
}
.ant-picker-outlined {
  background-color: var(--bg-global-modal-dark) !important;
  color: var(--text-color-modal) !important;
}
.ant-picker .ant-picker-suffix {
  color: var(--text-color-modal) !important;
}
textarea#description {
  border-radius: 6px;
  background: var(--bg-global-wrapper) !important;
}

.ant-picker-dropdown,
.ant-picker-panel {
  background-color: var(--bg-global-modal-dark) !important;
}
.ant-picker-dropdown .ant-picker-content th {
  color: var(--text-color-modal) !important;
}
.ant-picker-dropdown .ant-picker-cell-in-view {
  color: var(--text-color-modal) !important;
}
.ant-picker-dropdown .ant-picker-header {
  color: var(--text-color-modal) !important;
}
.ant-picker-dropdown .ant-picker-header button {
  color: var(--text-color-modal) !important;
}
.ant-picker-dropdown .ant-picker-footer {
  background-color: var(--bg-global-modal-dark) !important;
}
.ant-picker-dropdown .ant-picker-footer .ant-picker-now-btn {
  color: var(--text-color-modal) !important;
}

.ant-picker-outlined {
  background-color: var(--bg-global-modal-dark) !important;
  color: var(--text-color-modal) !important;
}
.ant-picker .ant-picker-suffix {
  color: var(--text-color-modal) !important;
}
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item-icon {
  margin-inline-end: 2px !important;
}
.icon {
  color: var(--text-color-modal) !important;
}
.ant-form-item-with-help .ant-form-item-explain {
  height: auto;
  opacity: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.drop-dow-list-doc {
  height: 320px !important;
  overflow-y: scroll;
}
.folio-number {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
}
.tooltip-email {
  max-width: 370px !important;
}
.tooltip-email span {
  color: #1677ff !important;
}
.recharts-tooltip-wrapper-right {
  visibility: visible !important;
}
.section-options-multi {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
}
.section-options-multi .sub-avatar {
  position: relative !important;
  right: 10px !important;
  bottom: -7px !important;
}
.content-avatar-multi {
  width: 40px;
  /* background-color: red; */
}
.content-info-modal {
  padding: 22px 6px;
}
.confirm-updated p {
  margin: 0px !important;
}
.title-modal-calendar-custom {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
}
.title-modal-calendar-custom h3 {
  margin: 0px !important;
  font-size: 16px;
}
.popover-notification {
  width: 364px !important;
}
